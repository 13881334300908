import React, { Component } from 'react';
import * as emailjs from 'emailjs-com'
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Alert
} from 'reactstrap';

const styles = {
    form: {
        
    },
    space: {
        height: '15px'
    }
}

export default class ProductModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formSelected: false,
            name: "",
            company: "",
            email: "",
            phone: "",
            pricing: false,
            success: null,
            isValid: false,
            alert_message: ''
        }

        this.toggleForm = this.toggleForm.bind(this)
        this.onChangePhone = this.onChangePhone.bind(this)
        this.onChangeName = this.onChangeName.bind(this)
        this.onChangeEmail = this.onChangeEmail.bind(this)
        this.onChangeCompany = this.onChangeCompany.bind(this)
        this.onChangePricing = this.onChangePricing.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    componentDidMount() {
        this.setState({
            sucess: null,
            isValid: false
        })
    }

    onChangeName(e) {
        var name = e.target.value;
        this.setState({
            name
        })
    }

    onChangeCompany(e) {
        var company = e.target.value;
        this.setState({
            company
        })
    }

    onChangeEmail(e) {
        var email = e.target.value;
        this.setState({
            email
        })
    }

    onChangePhone(e) {
        var phone = e.target.value;
        this.setState({
            phone
        })
    }

    onChangePricing(e) {
        var pricing = e.target.checked;
        this.setState({
            pricing
        })
    }

    toggleForm() {
        this.setState({
            formSelected: !this.state.formSelected
        })
    }

    onSubmit() {
        let success, alert_message, pricing;
        let from_name = this.state.name
        let reply_to = this.state.email
        if (this.state.pricing) {
            pricing = "Yes"
        } else {
            pricing = "No"
        }

        if (from_name != '' & reply_to != '') {
            var isValid = true
            this.setState({
                isValid
            }, () => {
                console.log(this.state.isValid, 'isValid')
                if (this.state.isValid) {
                    console.log('Starting to send Inquiry')
                    var templateParams = {
                        from_name,
                        reply_to,
                        subject: "Inquiry about " + this.props.item.title,
                        company: this.state.company,
                        phone: this.state.phone,
                        pricing
                    }
                    var result = emailjs.send('anthony_kawa', '10g_inquiry', templateParams, 'user_dpK8HokkuWiiNX2Je2fUP')
                        .then(function (response) {
                            console.log('SUCCESS!', response.status, response.text)
                            success = true
                            alert_message = "Inquiry has been successfully sent!"
                        }, function (err) {
                            console.log('FAILED...', err)
                            success = false
                            alert_message = "Inquiry failed to send! Please try again."
                        })
                        .then(() => {
                            this.setState({
                                name: '',
                                company: '',
                                email: '',
                                phone: '',
                                success,
                                pricing: false,
                                alert_message,
                                isValid: false
                            }, () => {
                                setTimeout(
                                    function () {
                                        this.setState({
                                            success: null
                                        })
                                    }
                                        .bind(this),
                                    3000
                                )
                            })
                        })

                }
            })
        } else {
            console.log('Oh no! Not all required fields are filled out.')
            this.setState({
                isValid: false,
                success: false,
                alert_message: 'Oh no! Not all required fields are filled out.'
            })
        }


    }

    render() {
        const item = this.props.item;
        const features = item.features;
        const title = item.title;
        const description = item.description;
        const image = item.mainImage;
        const pbLink = item.pb_link;
        let formSelected = this.state.formSelected || null;
        let isValid = this.state.isValid
        let success = this.state.success
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.toggle(0)} >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
                            <button onClick={this.props.toggle(0)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="image-container">
                                <img className="img-fluid" src={image} />
                            </div>
                            <h6>Features</h6>
                            <ul>
                                {features.map((feature, id) => {
                                    return (
                                        <li key={id}>{feature}</li>
                                    )
                                })}
                            </ul>
                            <h6>Description</h6>
                            <p>{description}</p>
                            <a href="javascript:void(0)" onClick={this.toggleForm}><h6>Get More Information {formSelected ? <span>&#9661;</span> : <span>&#9655;</span>}</h6></a>
                            <div style={styles.space}></div>
                            {formSelected ?
                                <div style={styles.form}>
                                <hr />
                                    {success == false & !isValid ? <Alert color="danger">{this.state.alert_message}</Alert> : null}
                                    {success == true ? <Alert color="success">{this.state.alert_message}</Alert> : null}
                                    <FormGroup>
                                        <Label for="name">Full Name <span style={{ color: 'red' }}>(required)</span></Label>
                                        <Input type="text" name="name" id="name" placeholder="John Doe" onChange={this.onChangeName} value={this.state.name} required />
                                        <Label for="email">Email <span style={{ color: 'red' }}>(required)</span></Label>
                                        <Input type="email" name="email" id="email" placeholder="johndoe@example.com" onChange={this.onChangeEmail} value={this.state.email} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="company">Company</Label>
                                        <Input type="text" name="company" id="company" placeholder="ABC, inc." onChange={this.onChangeCompany} value={this.state.company} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="phone">Phone Number</Label>
                                        <Input type="phone" name="phone" id="phone" placeholder="(555) 555-5555" onChange={this.onChangePhone} value={this.state.phone} />
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="checkbox" onChange={this.onChangePricing} value="pricing" checked={this.state.pricing} />{' '}
                                            I'm interested in pricing
                                        </Label>
                                    </FormGroup>
                                    <div style={styles.space}></div>
                                    <Button styles={styles.button} onClick={this.onSubmit}>Send</Button>
                                    <hr />
                                </div>
                                :
                                null
                            }
                            <h6>Product Brief</h6>
                            <a href={pbLink} target="_blank">Click to download</a>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}