// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("C:\\Users\\antho\\working\\10g-gatsby-next\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("C:\\Users\\antho\\working\\10g-gatsby-next\\src\\pages\\about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("C:\\Users\\antho\\working\\10g-gatsby-next\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\antho\\working\\10g-gatsby-next\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-support-js": () => import("C:\\Users\\antho\\working\\10g-gatsby-next\\src\\pages\\support.js" /* webpackChunkName: "component---src-pages-support-js" */)
}

exports.data = () => import("C:\\Users\\antho\\working\\10g-gatsby-next\\.cache\\data.json")

