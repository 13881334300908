import React, { Component } from 'react'
import { withPrefix } from 'gatsby-link';

import { items } from '../data/Items'

import HomeCarousel from '../components/HomeCarousel';
import ProductModal from '../components/ProductModal';

import Layout from '../components/layout'

require('es6-promise').polyfill();
require('isomorphic-fetch');


class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      item: items[0],
      modal: false
    }
  }

  toggleModal = (id) => {
    this.itemSelect(id)
    this.toggle()
  }

  itemSelect = (id) => {
    this.setState({
      item: items[id]
    })
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    return (
      <Layout>
        <div>
          <ProductModal item={this.state.item} modal={this.state.modal} toggle={() => this.toggle} />
          <HomeCarousel items={items} itemSelect={() => this.toggleModal} />

          <div className="container">
            <div className="row feature-boxes" style={{ padding: '50px 0' }}>
              <div className="col-lg-4">
                <h4>what's new</h4>
                <div className="feature-box">
                  <div style={{ height: '250px', overflow: 'hidden' }}>
                    <img className="img-fluid" src={withPrefix('/images/USBtoNBASE-T.png')} alt="" />
                  </div>
                  <div className="feature-textbox">
                    <p>Sunrich has been hard at work, developing our next generation networking products and have developed
                            the first USB&#8209;C to NBASE&#8209;T Adapter of it's kind. </p>
                    <a href="javascript:void(0)" onClick={() => { this.toggleModal(1); }} id="USB_NBASE-T_link">Click the link to read More</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <h4>follow us</h4>
                <div className="feature-box">
                  <div style={{ height: '250px', overflow: 'hidden' }}>
                    <img width="450px" src={withPrefix('/images/linkedin.jpg')} alt="" />
                  </div>
                  <div className="feature-textbox">
                    <p>Not already following us on LinkedIn? Come check us out, and make sure you hit to follow button to
                            keep yourself updated on the new projects we are working on.</p>
                    <a href="https://www.linkedin.com/company/sunrich-technology/" target="_blank">Visit our LinkedIn profile</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <h4>Partnership</h4>
                <div className="feature-box">
                  <div style={{ height: '250px', overflow: 'hidden' }}>
                    <img className="img-fluid" src={withPrefix('/images/AquantiaChip_lg.jpg')} alt="" />
                  </div>
                  <div className="feature-textbox">
                    <p>Sunrich is the first qualified hardware partner for Aquantia, which will ensure release of the latest
                            generation Multi-Gig products, and market leading hardware support.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
